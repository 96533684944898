<template>
  <v-card flat>
    <v-row class="justify-space-between mt-1">
      <div>
        <v-btn class="ml-3 mt-1" elevation="5" @click="back()">
          <v-icon large :left="$vuetify.breakpoint.smAndUp" color="primary">mdi-arrow-left-circle</v-icon>
          <div class="hidden-xs-only ml-2 font-weight-bold">Назад</div>
        </v-btn>
      </div>
      <div>
        <v-btn class="mt-1 mr-3" v-if="role ==='ADMIN' || role === 'MONITOR'" elevation="3" @click="toActive(order.id)">
          <v-icon large :left="$vuetify.breakpoint.smAndUp" color="error">mdi-update</v-icon>
          <div class="hidden-xs-only ml-2 font-weight-bold">Перевести в активные</div>
        </v-btn>
        <v-dialog v-model="cashDialog" width="400px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" v-if="order.payment_system === 'NONE' && role ==='ADMIN'" elevation="3"
                   class="mr-3 mt-1">
              <v-icon large :left="$vuetify.breakpoint.smAndUp" color="warning">mdi-credit-card</v-icon>
              <div class="hidden-xs-only ml-2 font-weight-bold">Перевести в без. нал.</div>
            </v-btn>
          </template>
          <v-card shaped class="pa-3">
            <v-toolbar color="primary" dense dark>
              <v-btn class="mr-1 row justify-end" icon>
                <v-icon @click="cashDialog = false" large>mdi-close</v-icon>
              </v-btn>
            </v-toolbar>
            <div class="mx-3 mt-5">
              <v-autocomplete v-model="order.payment_system"
                              :items="payment_system"
                              dense
                              outlined
                              rounded
                              label="выберите систему оплаты"
                              hide-details/>
            </div>
            <v-row no-gutters class="justify-end pt-3 mx-5">
              <v-btn color="primary" rounded small @click="makeCashless()">
                Перевести
              </v-btn>
            </v-row>
          </v-card>
        </v-dialog>
      </div>
    </v-row>
    <v-row>
      <v-col cols="12" class="col col-lg-4 col-md-4 col-sm-4">
        <v-card elevation="2">
          <v-img :height="height" src="/user_background.jpeg">
            <v-card-title class="white--text ml-1">
              <v-row no-gutters>
                <v-avatar class="col-12" size="56">
                  <img alt="" src="https://cdn0.iconfinder.com/data/icons/simple-line-1/100/1-37-512.png">
                </v-avatar>
                <v-tooltip top v-if="order.partner">
                  <template v-slot:activator="{attrs, on}">
                    <v-row dense class="mt-2 col-12 justify-center indigo--text">
                      <span v-bind="attrs" v-on="on">№ {{ order.number }}</span>
                    </v-row>
                  </template>
                  <span>от Партнера</span>
                </v-tooltip>
                <v-row v-else dense class="mt-2 col-12 justify-center">
                  <span>№ {{ order.number }}</span>
                </v-row>
                <v-row>
                  <v-col cols="6" class="row no-gutters justify-center py-0 d-flex align-center">
                    <span class="text-body-1 text-no-wrap">Оценка заведению</span>
                  </v-col>
                  <v-col cols="6" class="row no-gutters justify-center py-0 d-flex align-center">
                    <v-rating v-model="order.store_rating"
                              background-color="indigo"
                              color="indigo"
                              dense
                              empty-icon="mdi-star-outline"
                              full-icon="mdi-star"
                              hover
                              length="5"
                              readonly/>
                  </v-col>
                  <v-col cols="12" class="row no-gutters justify-center py-0 d-flex align-center">
                    <span class="text-body-1 font-italic">{{ order.store_rating_comment }}.</span>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6" class="row no-gutters justify-center py-0 d-flex align-center">
                    <span class="text-body-1">Оценка курьеру</span>
                  </v-col>
                  <v-col cols="6" class="row no-gutters justify-center py-0 d-flex align-center">
                    <v-rating v-model="order.driver_rating"
                              background-color="indigo"
                              color="indigo"
                              dense
                              empty-icon="mdi-star-outline"
                              full-icon="mdi-star"
                              hover
                              length="5"
                              readonly/>
                  </v-col>
                  <v-col cols="12" class="row no-gutters justify-center py-0 d-flex align-center">
                    <span class="text-body-1 font-italic">{{ order.driver_rating_comment }}.</span>
                  </v-col>
                </v-row>
              </v-row>
            </v-card-title>
          </v-img>
          <v-card-text>
            <div class="ml-9 font-weight-bold mb-1">
              <v-icon>mdi-source-commit-start-next-local</v-icon>
            </div>
            <v-timeline dense class="pt-0">
              <v-timeline-item class="py-0" color="blue" fill-dot icon="mdi-clock-outline" small>
                <v-card flat>
                  <v-card-subtitle class="mx-0 px-0">
                    <v-row no-gutters class="d-flex align-center font-weight-bold">
                      <v-col cols="4">
                        <div>{{ timeFormat(order.started_at) }}</div>
                        <div>{{ dateFormat(order.started_at) }}</div>
                      </v-col>
                      <v-col cols="8">
                        <v-row no-gutters class="justify-center text-center">
                          {{ order.client.name ? order.client.name : "Без имени" }}
                        </v-row>
                        <v-row no-gutters class="justify-center text-center">{{
                            order.client.phone | normalizePhone
                          }}
                        </v-row>
                      </v-col>
                    </v-row>
                    <v-row no-gutters class="justify-center d-flex align-center mt-2">
                      <strong class="text-no-wrap">{{ order.address }}</strong>
                      <strong class="text-no-wrap">{{ order.description }}</strong>
                    </v-row>
                  </v-card-subtitle>
                </v-card>
              </v-timeline-item>
              <v-timeline-item v-if="order.confirmed_at !== order.started_at" class="py-0" color="blue" fill-dot
                               icon="mdi-account-check" small>
                <v-card flat>
                  <v-card-subtitle class="mx-0 px-0">
                    <v-row no-gutters class="d-flex align-center font-weight-bold">
                      <v-col cols="4">
                        <div>{{ timeFormat(order.confirmed_at) }}</div>
                        <div>{{ dateFormat(order.confirmed_at) }}</div>
                      </v-col>
                      <v-col cols="8">
                        <v-row no-gutters class="justify-center text-center">{{ order.confirm_reason }}</v-row>
                      </v-col>
                    </v-row>
                  </v-card-subtitle>
                </v-card>
              </v-timeline-item>
              <v-timeline-item class="py-0" color="blue darken-3" fill-dot icon="mdi-silverware" small>
                <v-card flat>
                  <v-card-subtitle class="px-0 mx-0">
                    <v-row no-gutters class="d-flex align-center font-weight-bold">
                      <v-col cols="4">
                        <div>{{ timeFormat(order.store_started_at) }}</div>
                        <div>{{ dateFormat(order.store_started_at) }}</div>
                      </v-col>
                      <v-col cols="8">
                        <v-row no-gutters class="justify-center text-center">{{ order.store.name }}</v-row>
                        <v-row no-gutters class="justify-center text-center">{{
                            order.store.phone | normalizePhone
                          }}
                        </v-row>
                      </v-col>
                    </v-row>
                    <v-row no-gutters class="justify-center d-flex align-center mt-2">
                      <strong class="pa-0 justify-center text-no-wrap">{{ order.store.address }}</strong>
                    </v-row>
                  </v-card-subtitle>
                </v-card>
              </v-timeline-item>
              <v-timeline-item class="py-0" color="purple" fill-dot icon="mdi-moped" small>
                <v-card flat>
                  <v-card-subtitle class="mx-0 px-0">
                    <v-row no-gutters class="d-flex align-center font-weight-bold">
                      <v-col cols="4">
                        <div>{{ timeFormat(order.driver_confirmed_at) }}</div>
                        <div>{{ dateFormat(order.driver_confirmed_at) }}</div>
                      </v-col>
                      <v-col cols="8">
                        <v-row class="justify-center text-center">{{ order.driver.name }}</v-row>
                        <v-row class="justify-center text-center">{{ order.driver.phone | normalizePhone }}</v-row>
                      </v-col>
                    </v-row>
                  </v-card-subtitle>
                </v-card>
              </v-timeline-item>
              <v-timeline-item class="py-0" color="blue darken-3" fill-dot icon="mdi-clipboard-check" small>
                <v-card flat>
                  <v-card-subtitle class="px-0 mx-0">
                    <v-row no-gutters class="d-flex align-center font-weight-bold">
                      <v-col cols="4">
                        <div>{{ timeFormat(order.store_finished_at) }}</div>
                        <div>{{ dateFormat(order.store_finished_at) }}</div>
                      </v-col>
                      <v-col cols="8">
                        <v-row no-gutters class="justify-center">
                          <span class="font-italic">Приготовлено</span>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-card-subtitle>
                </v-card>
              </v-timeline-item>
              <v-timeline-item class="py-0" color="purple" fill-dot icon="mdi-truck-fast" small>
                <v-card flat>
                  <v-card-subtitle class="px-0 mx-0">
                    <v-row no-gutters class="d-flex align-center font-weight-bold">
                      <v-col cols="4">
                        <div>{{ timeFormat(order.driver_started_at) }}</div>
                        <div>{{ dateFormat(order.driver_started_at) }}</div>
                      </v-col>
                      <v-col cols="8">
                        <v-row class="justify-center">
                          <span class="font-italic">Доставляется</span>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-card-subtitle>
                </v-card>
              </v-timeline-item>
              <v-timeline-item class="py-0" color="primary" fill-dot icon="mdi-check" small>
                <v-card flat>
                  <v-card-subtitle class="px-0 mx-0">
                    <v-row no-gutters class="d-flex align-center font-weight-bold">
                      <v-col cols="4">
                        <div>{{ timeFormat(order.finished_at) }}</div>
                        <div>{{ dateFormat(order.finished_at) }}</div>
                      </v-col>
                      <v-col cols="8">
                        <v-row no-gutters class="justify-center">
                          <span class="font-italic text-center">Успешно доставлено</span>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-card-subtitle>
                </v-card>
              </v-timeline-item>
            </v-timeline>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" class="col col-lg-8 col-md-8 col-sm-8">
        <v-card elevation="2">
          <v-toolbar color="teal" dark height="50">
            <v-toolbar-title class="row justify-center font-weight-bold text-body-1">{{ order.store.name }}
            </v-toolbar-title>
          </v-toolbar>
          <v-row dense class="mt-3">
            <v-col cols="6" class="col col-lg-3 col-md-3 col-sm-3 row no-gutters justify-center d-flex align-center">
              <span class="caption font-weight-bold mr-3">Блюдо</span>
            </v-col>
            <v-col cols="4" class="col col-lg-5 col-md-5 col-sm-5 row no-gutters justify-end d-flex align-center">
              <span class="caption font-weight-bold mr-8">Кол-во</span>
            </v-col>
            <v-col cols="2" class="col col-lg-4 col-md-4 col-sm-4 row no-gutters justify-end d-flex align-center">
              <span class="caption font-weight-bold mr-6">Сумма</span>
            </v-col>
          </v-row>
          <v-row no-gutters class="my-2 pr-5" v-for="item in items" :key="item.id">
            <v-col cols="2" class="col col-lg-1 col-md-1 col-sm-1 row no-gutters justify-center d-flex align-center">
              <v-avatar size="36px">
                <img alt="" v-if="item.image" :src="item.image"/>
                <img alt="" v-else src="/item_placeholder.png"/>
              </v-avatar>
            </v-col>
            <v-col cols="5" class="col col-lg-4 col-md-4 col-sm-4 row no-gutters justify-start d-flex align-center">
              <span class="text-body-2">{{ item.name }}</span>
            </v-col>
            <v-col cols="3" class="col col-lg-5 col-md-5 col-sm-5 row no-gutters justify-center d-flex align-center">
              <span>{{ item.count }}</span>
            </v-col>
            <v-col cols="2" class="row no-gutters justify-end d-flex align-center">
              <span v-if="item.discount > 0" class="text-decoration-line-through mr-4">{{ item.price }} c.</span>
              <span>{{ item.price - item.discount }} c.</span>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row no-gutters class="py-2 pr-5">
            <v-col cols="5" class="col col-lg-3 col-md-3 col-sm-3 row no-gutters justify-center d-flex align-center">
              <span :class="$vuetify.breakpoint.xs ? 'pl-3' : 'pr-7'" class="font-weight-bold">Итого</span>
            </v-col>
            <v-col cols="7" class="col col-lg-9 col-md-9 col-sm-9 row no-gutters justify-end d-flex align-center">
              <span class="font-weight-bold">{{ sum }} c.</span>
            </v-col>
          </v-row>
        </v-card>
        <v-card elevation="2" class="mt-4">
          <v-toolbar color="lime darken-2" dark height="50">
            <v-toolbar-title class="row justify-center font-weight-bold text-body-1">О заказе</v-toolbar-title>
          </v-toolbar>
          <v-row dense class="mt-3">
            <v-col cols="6" class="col col-lg-3 col-md-3 col-sm-3 row no-gutters justify-center d-flex align-center">
              <span class="caption font-weight-bold">Детали</span>
            </v-col>
            <v-col cols="6" class="col col-lg-9 col-md-9 col-sm-9 row no-gutters justify-end d-flex align-center">
              <span class="caption font-weight-bold mr-6">Сумма</span>
            </v-col>
          </v-row>
          <v-row no-gutters class="my-2">
            <v-col cols="2" class="col col-lg-1 col-md-1 col-sm-1 row no-gutters justify-center d-flex align-center">
              <v-icon color="indigo" large>mdi-file-document</v-icon>
            </v-col>
            <v-col cols="6" class="col col-lg-7 col-md-7 col-sm-7 row no-gutters justify-start d-flex align-center">
              <span class="text-body-2">Стоимость блюд</span>
            </v-col>
            <v-col cols="4" class="row no-gutters justify-end d-flex align-center">
              <span class="mr-5">{{ order.store_price }} c.</span>
            </v-col>
          </v-row>
          <v-row no-gutters class="my-2">
            <v-col cols="2" class="col col-lg-1 col-md-1 col-sm-1 row no-gutters justify-center d-flex align-center">
              <v-icon color="indigo" large>mdi-sale</v-icon>
            </v-col>
            <v-col cols="6" class="col col-lg-7 col-md-7 col-sm-7 row no-gutters justify-start d-flex align-center">
              <span class="text-body-2">Скидка</span>
            </v-col>
            <v-col cols="4" class="row no-gutters justify-end d-flex align-center">
              <span class="mr-5">{{ order.discount }} c.</span>
            </v-col>
          </v-row>
          <v-row no-gutters class="my-2">
            <v-col cols="2" class="col col-lg-1 col-md-1 col-sm-1 row no-gutters justify-center d-flex align-center">
              <v-icon color="indigo" large>mdi-clock-fast</v-icon>
            </v-col>
            <v-col cols="6" class="col col-lg-7 col-md-7 col-sm-7 row no-gutters justify-start d-flex align-center">
              <span class="text-body-2">Доставка с заведения</span>
            </v-col>
            <v-col cols="4" class="row no-gutters justify-end d-flex align-center">
              <span class="mr-5">{{ order.store_delivery_price }} c.</span>
            </v-col>
          </v-row>
          <v-row no-gutters class="my-2">
            <v-col cols="2" class="col col-lg-1 col-md-1 col-sm-1 row no-gutters justify-center d-flex align-center">
              <v-icon color="indigo" large>mdi-clock-fast</v-icon>
            </v-col>
            <v-col cols="6" class="col col-lg-7 col-md-7 col-sm-7 row no-gutters justify-start d-flex align-center">
              <span class="text-body-2">Доставка с клиента</span>
            </v-col>
            <v-col cols="4" class="row no-gutters justify-end d-flex align-center">
              <span class="mr-5">{{ order.delivery_price - order.store_delivery_price }} c.</span>
            </v-col>
            <v-row class="pt-1" no-gutters v-if="order.payment_system === 'PAYBOX'">
              <v-col cols="2" class="col col-lg-1 col-md-1 col-sm-1 row no-gutters justify-center d-flex align-center">
                <v-icon color="orange" large>mdi-credit-card</v-icon>
              </v-col>
              <v-col cols="6" class="col col-lg-7 col-md-7 col-sm-7 row no-gutters justify-start d-flex align-center">
                <span>{{ order.payment_system }} ({{ order.paybox_system }})</span>
              </v-col>
              <v-col cols="4" class="row no-gutters justify-end d-flex align-center">
                <span class="font-weight-bold mr-5">{{ order.paybox_id }}</span>
              </v-col>
            </v-row>
            <v-row class="pt-1" no-gutters v-else-if="order.payment_system === 'ODENGI'">
              <v-col cols="2" class="col col-lg-1 col-md-1 col-sm-1 row no-gutters justify-center d-flex align-center">
                <v-icon color="orange" large>mdi-alpha-o-circle-outline</v-icon>
              </v-col>
              <v-col cols="6" class="col col-lg-7 col-md-7 col-sm-7 row no-gutters justify-start d-flex align-center">
                <span>{{ order.payment_system }}</span>
              </v-col>
              <v-col cols="4" class="row no-gutters justify-end d-flex align-center">
                <span class="font-weight-bold mr-5">{{ order.paybox_id }}</span>
              </v-col>
            </v-row>
            <v-row class="pt-1" no-gutters v-else-if="order.payment_system === 'BALANCE'">
              <v-col cols="2" class="col col-lg-1 col-md-1 col-sm-1 row no-gutters justify-end d-flex align-center">
                <v-icon color="orange" large>mdi-alpha-b-circle-outline</v-icon>
              </v-col>
              <v-col cols="6" class="col col-lg-7 col-md-7 col-sm-7 row no-gutters justify-start d-flex align-center">
                <span>{{ order.payment_system }}</span>
              </v-col>
              <v-col cols="4" class="row no-gutters justify-end d-flex align-center">
                <span class="font-weight-bold mr-5">{{ order.paybox_id }}</span>
              </v-col>
            </v-row>
          </v-row>
          <v-divider></v-divider>
          <v-row no-gutters class="py-2">
            <v-col cols="2" class="col col-lg-1 col-md-1 col-sm-1 row no-gutters justify-center d-flex align-center">
              <v-icon color="primary" large>mdi-buffer</v-icon>
            </v-col>
            <v-col cols="6" class="col col-lg-7 col-md-7 col-sm-7 row no-gutters justify-start d-flex align-center">
              <span class="font-weight-bold">Итого</span>
            </v-col>
            <v-col cols="4" class="row no-gutters justify-end d-flex align-center">
              <span class="font-weight-bold mr-5">{{ order.total_price - order.discount }} c.</span>
            </v-col>
            <v-row class="pt-1" no-gutters v-if="order.payment_system === 'PAYBOX'">
              <v-col cols="2" class="col col-lg-1 col-md-1 col-sm-1 row no-gutters justify-center d-flex align-center">
                <v-icon color="orange" large>mdi-credit-card</v-icon>
              </v-col>
              <v-col cols="6" class="col col-lg-7 col-md-7 col-sm-7 row no-gutters justify-start d-flex align-center">
                <span>{{ order.payment_system }} ({{ order.paybox_system }})</span>
              </v-col>
              <v-col cols="4" class="row no-gutters justify-end d-flex align-center">
                <span class="font-weight-bold mr-5">{{ order.paybox_id }}</span>
              </v-col>
            </v-row>
            <v-row class="pt-1" no-gutters v-else-if="order.payment_system === 'ODENGI'">
              <v-col cols="2" class="col col-lg-1 col-md-1 col-sm-1 row no-gutters justify-center d-flex align-center">
                <v-icon color="orange" large>mdi-alpha-o-circle-outline</v-icon>
              </v-col>
              <v-col cols="6" class="col col-lg-7 col-md-7 col-sm-7 row no-gutters justify-start d-flex align-center">
                <span>{{ order.payment_system }}</span>
              </v-col>
              <v-col cols="4" class="row no-gutters justify-end d-flex align-center">
                <span class="font-weight-bold mr-5">{{ order.paybox_id }}</span>
              </v-col>
            </v-row>
            <v-row class="pt-1" no-gutters v-else-if="order.payment_system === 'BALANCE'">
              <v-col cols="2" class="col col-lg-1 col-md-1 col-sm-1 row no-gutters justify-center d-flex align-center">
                <v-icon color="orange" large>mdi-alpha-b-circle-outline</v-icon>
              </v-col>
              <v-col cols="6" class="col col-lg-7 col-md-7 col-sm-7 row no-gutters justify-start d-flex align-center">
                <span>{{ order.payment_system }}</span>
              </v-col>
              <v-col cols="4" class="row no-gutters justify-end d-flex align-center">
                <span class="font-weight-bold mr-5">{{ order.paybox_id }}</span>
              </v-col>
            </v-row>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import axios from "@/services/axios";
import format from "date-fns/format";

export default {
  data() {
    return {
      activeDialog: false,
      cashDialog: false,
      payment_system: [
        'PAYBOX',
        'ODENGI',
        'BALANCE'
      ],
      menuCol: "col-8",
      height: 230,
    }
  },
  mounted() {
    this.fetch(this.$route.params.id);
  },
  computed: {
    role() {
      return this.$store.getters["auth/role"];
    },
    sum() {
      let total = 0;
      for (let i = 0; i < this.items.length; i++) {
        total = total + this.items[i].price - this.items[i].discount;
      }
      return total;
    },
    items() {
      return this.$store.getters["order/items"];
    },
    order() {
      return this.$store.getters["order/order"];
    },
  },
  methods: {
    toActive(id) {
      this.$confirm(
          "Перевести в Активные",
          "Внимание",
          "question"
      ).then(() => {
        axios.send({
          url: "/orders/" + id,
          method: "DELETE",
        }).then(() => {
          this.$router.push({name: 'active_order', params: {id: id}})
        })
      })
    },
    makeCashless() {
      axios
          .send({
            url: "/orders/cashless",
            method: "POST",
            data: {id: this.order.id, payment_system: this.order.payment_system}
          })
          .then((resp) => {
            this.order = resp.data;
            this.dialog = false;
            this.$router.go();
          });
    },
    fetch(id) {
      this.$store.dispatch("order/fetch", id).then(() => {
        if (this.order.id) {
          this.fetchItems();
        }
      })
    },
    fetchItems() {
      this.$store.dispatch("order/fetchItems", this.order.id);
    },
    timeFormat(d) {
      if (d) {
        return format(new Date(d), "HH:mm")
      }
      return ''
    },
    dateFormat(d) {
      if (d) {
        return format(new Date(d), "dd-MM-yy")
      }
      return ''
    },
    back() {
      this.$router.push({name: 'orders'});
    }
  },
  filters: {
    normalizePhone: (p) => {
      if (!p) {
        return ""
      }
      let result = "";
      let phones = p.split(",");

      phones.forEach(phone => {
        phone = phone.replaceAll("+", "").replaceAll(" ", "")
        let temp = "+"
        for (let i = 0; i < phone.length; i++) {
          if (i % 3 === 0) {
            temp = temp + " "
          }
          temp = temp + phone[i]
        }
        result = result + temp + ","
      })

      return result.substr(0, result.length - 1)
    },
  },
}
</script>
